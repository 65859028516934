<template>
<el-dialog
  title="微信支付"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <div class="WeChatPayment">
    <h3>需支付{{data.TotalMoney | priceToFixed}}元</h3>
    <div id="qrcode" ref="qrcode"></div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="handleFind()" size="mini">已支付</el-button>
  </span>
</el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props:["data","dialogVisible"],
  data() {
    return {
      msg: "555",
      // dialogVisible:false,
      code:"",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods:{
    init() {
      this.code = this.data.code;
      new QRCode('qrcode',{
        width: 250, // 设置宽度，单位像素
        height: 250, // 设置高度，单位像素
        text: this.code, // 设置二维码内容或跳转地址
      })
    },
    handleFind(){
      this.$emit("close");
      if (this.data.order !== undefined &&this.data.order!='商城订单') {
        this.$router.push({ path: "/personal/myOrder"});
      }else if(this.data.order !== undefined &&this.data.order=='商城订单'){
        this.$router.push({ path: "/PCshop/my/shopmy"});
      }
     
    },
    handleClose(){
      this.$emit("close");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .WeChatPayment{
    text-align: center;
      #qrcode{
        margin-top: 30px;
        /deep/img{display: inline-block !important;}
      }
  }
</style>
